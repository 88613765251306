;(function (angular, Azure, undefined) {
   'use strict'

   angular
      .module('app.routes', ['ui.router', 'oc.lazyLoad'])
      .constant('loadOnTransition', function loadOnTransition(files, templates, optionalFiles, callback) {
         return function (transition) {
            var injector = transition.injector()
            var $ocLazyLoad = injector.get('$ocLazyLoad')
            var $q = injector.get('$q')
            var filesPromise = $ocLazyLoad.load(files).catch(function (error) {
               var targetState = transition.targetState()
               var href = transition.router.stateService.href(
                  targetState.name(),
                  targetState.params(),
                  targetState.options()
               )
               return injector
                  .get('modalService')
                  .checkForNewVersion(href)
                  .finally(function () {
                     return $q.reject(error)
                  })
            })

            // Optional files are allowed to fail without causing a transition error
            var optionalFilesPromise = $ocLazyLoad.load(optionalFiles).catch(angular.noop)

            // If templates are specified, preload them in parallel
            if (templates) {
               var $templateRequest = injector.get('$templateRequest')
               templates = Array.isArray(templates) ? templates : [templates]
               templates.forEach(function (template) {
                  $templateRequest(template, true).catch(angular.noop)
               })
            }

            return $q.all([filesPromise, optionalFilesPromise]).then(callback)
         }
      })
      .constant('fetchPageData', function (url, cmsDataRequestKey) {
         /* @ngInject */
         function fetchPageData($transition$, $http, $q) {
            var dataRequestUrl = Azure.isCmsPreview
               ? (dataRequestUrl =
                    Azure.serverlessEndpointsApiUrl +
                    '/fetch-data-from-cms?entrySlug={slug}&entryUuid={uuid}&cmsDataRequestKey=' +
                    cmsDataRequestKey)
               : url

            dataRequestUrl = dataRequestUrl
               .replace('{uuid}', $transition$.params().uuid)
               .replace('{slug}', $transition$.params().slug)

            return $http.get(dataRequestUrl, {cache: !Azure.isCmsPreview}).then(function (response) {
               if (response.data && Object.keys(response.data).length > 1 && typeof response.data === 'object') {
                  return response.data
               } else {
                  return $q.reject({
                     status: 404,
                  })
               }
            })
         }
         return fetchPageData
      })
      .constant('maybeRedirectCmsUuidBasedRoute', function () {
         /* @ngInject */
         function maybeRedirectCmsUuidBasedRoute($transition$, $state, $window, $urlService, pageData) {
            var toParams = $transition$.params()
            var toState = $transition$.to()
            var maybePageDataParentSlug = pageData.parent && pageData.parent.slug

            if (
               toParams.slug !== pageData.slug ||
               (maybePageDataParentSlug && maybePageDataParentSlug !== toParams.parentSlug)
            ) {
               toState.data.prerenderRedirectToCanonical = true
               return $state.target(
                  toState.name,
                  angular.extend({}, toParams, {
                     slug: pageData.slug,
                     uuid: pageData.uuid,
                     parentSlug: maybePageDataParentSlug,
                  })
               )
            } else if (pageData.archivedRedirectUrl) {
               var redirectUrl = new URL(pageData.archivedRedirectUrl)
               var matchResult = $urlService.match({
                  path: redirectUrl.pathname,
                  search: redirectUrl.search,
                  hash: redirectUrl.hash,
               })
               if (matchResult.rule.state) {
                  toState.data.prerenderRedirectToCanonical = true
                  return $state.target(matchResult.rule.state, matchResult.match)
               } else {
                  // Redirect URL didn't match a state
                  $window.location.href = pageData.archivedRedirectUrl
               }
            }
         }
         return maybeRedirectCmsUuidBasedRoute
      })
      .config(routesConfig)

   function routesConfig(
      $stateProvider,
      $urlServiceProvider,
      loadOnTransition,
      fetchPageData,
      maybeRedirectCmsUuidBasedRoute
   ) {
      // Uncomment to debug ocLazyLoad (remember to inject `$ocLazyLoadProvider` above)
      // $ocLazyLoadProvider.config({debug: true});

      $urlServiceProvider.config.type('slashesAllowed', {
         pattern: /.+/,
      })

      $urlServiceProvider.config.strictMode(false)

      // Child states inherit data.head from ancestor states
      // Child states can overwrite properties in data.head or define new properties that will be merged in
      $stateProvider.decorator('data', function (state, original) {
         // merge state's data.head with parent state's data.head
         var data = original(state) || {}

         var parentHead = state.parent && state.parent.data && state.parent.data.head

         if (data.head || parentHead) {
            data.head = angular.merge({}, parentHead, data.head)
         }
         state.self.data = data
         return data
      })

      var root = {
         url: '?superuser-for?organicOnly?cart?promoCode',
         abstract: true,
         templateUrl: 'inlineTemplates/root.htm',
         resolve: {
            /* @ngInject */
            priceSettings: function (appState) {
               return appState.userState.promise.then(function () {
                  return appState.userState.priceSettings
               })
            },
         },
         params: {
            cart: {
               type: 'bool',
               value: false,
               squash: true,
               dynamic: true,
            },
            featurePath: {
               type: 'string',
               value: null,
               dynamic: true,
            },
         },
         data: {
            // Note: These are effectively defaults. They can be overridden on a per-route basis either in the route
            // definition or from the Pug template.
            head: {
               // Note: The meta object is required even if empty.
               meta: {},
               primaryImageSrc:
                  'https://azurestandard.imgix.net/cms/default-primary.png?auto=format,compress&w=1200&h=630',
               primaryImageType: 'images/png',
               primaryImageAlt: 'Azure Standard Logo',
               og: {
                  type: 'website',
               },
            },
         },
      }

      var account = {
         url: '/my-account',
         lazyLoad: loadOnTransition('app.account/account.module.js'),
      }

      var add = {
         parent: 'root',
         url: '/add?code&campaignId&referringUrl&name&email&checkout&authKey',
         params: {
            code: {
               array: 'auto',
            },
         },
         resolve: {
            /* @ngInject */
            activeOrderState: function (appState) {
               return appState.activeOrderStatePromise
            },
         },
         templateUrl: 'app.add/add.htm',
         lazyLoad: loadOnTransition('app.add/add.controller.js', 'app.add/add.htm'),
         controller: 'AddCtrl',
         // If no codes passed, redirect to home
         redirectTo: function (transition) {
            if (!transition.params() || (transition.params() && !transition.params().code)) {
               return 'home'
            }
         },
      }

      var addResult = {
         parent: 'root',
         url: '/add/result',
         lazyLoad: loadOnTransition('app.add_result/add_result.controller.js', 'app.add_result/add_result.htm'),
         controller: 'AddResultCtrl',
         templateUrl: 'app.add_result/add_result.htm',
         params: {
            resultState: {
               dynamic: true,
            },
         },
      }

      var productShort = {
         parent: 'root',
         url: '/p/{id:[0-9]{1,8}}?package',
         redirectTo: function (transition) {
            return transition.router.stateService.target(
               'shop.product',
               angular.extend({}, transition.params(), {
                  path: null,
               })
            )
         },
      }

      var dc = {
         url: '/drop-coordinator',
         lazyLoad: loadOnTransition('app.dc/dc.module.js'),
      }

      var dd = {
         url: '/drop-driver',
         lazyLoad: loadOnTransition('app.dd/dd.module.js'),
      }

      var shop = {
         parent: 'root',
         url: '/shop',
         template: '<ui-view/>',
         redirectTo: 'shop.categoryRoot',
         data: {
            head: {
               meta: {
                  description:
                     'Azure Standard offers the highest quality organic food, natural beauty items, nutritional supplements, animal feed, organic gardening and eco-friendly products',
               },
            },
            shareable: true,
         },
      }

      var shopBrands = {
         url: '/brands',
         templateUrl: 'app.shop/brands.htm',
         lazyLoad: loadOnTransition('app.shop/brands.controller.js', 'app.shop/brands.htm'),
         data: {
            head: {
               title: 'Top Organic & Natural Food Companies - Independent Food Manufacturers',
               meta: {
                  description:
                     'Azure offers 14,000 items in a huge selection of natural food products from independent, organic producers and small growers.',
               },
            },
            shareable: true,
         },
         controller: 'BrandsCtrl',
         resolve: {
            /* @ngInject */
            allBrands: function (brandData) {
               return brandData.getAllBrands()
            },
         },
      }

      var shopProductOld = {
         url: '/product/{id:int}/',
         redirectTo: function (transition) {
            var productData = transition.injector().get('productData')
            return productData.getPackagedProductByOldId(transition.params().id).then(function (packagedProduct) {
               return transition.router.stateService.target('shop.product', {
                  id: packagedProduct.product,
                  path: null,
                  package: packagedProduct.code,
               })
            })
         },
      }

      var shopProduct = {
         url: '/product/{path}/{id:[0-9]{1,8}}?package',
         params: {
            path: {
               raw: true,
               type: 'string',
            },
            package: {
               dynamic: true,
            },
         },
         templateUrl: 'app.shop/product.index.htm',
         lazyLoad: loadOnTransition(
            ['app.shop/product.index.controller.js', 'app.shop/product.image_gallery.directive.js'],
            ['app.shop/product.index.htm', 'partials/quantity_btn_group.htm'],
            ['/lazy-dependencies/easyzoom.2.5.2.js']
         ),
         data: {
            head: {
               meta: {
                  description: '',
               },
               og: {
                  type: 'product',
               },
            },
            shareable: true,
         },
         resolve: {
            /* @ngInject */
            orderedPackagedProducts: function (appState) {
               return appState.userState.orderedPackagedProductsPromise
            },
            /* @ngInject */
            product: function (
               $transition$,
               $q,
               $state,
               appState,
               viewService,
               priceSettings,
               orderedPackagedProducts
            ) {
               var toParams = $transition$.params()
               var productId = parseInt(toParams.id)
               var options = {
                  priceSettings: priceSettings,
                  labels: true,
                  categoryView: {
                     ancestorViews: true,
                  },
               }
               if (orderedPackagedProducts) {
                  options.codes = orderedPackagedProducts
                     .filter(function (orderedPackagedProduct) {
                        return orderedPackagedProduct.productId === productId
                     })
                     .map(function (orderedPackagedProduct) {
                        return orderedPackagedProduct.code
                     })
               } else {
                  options.codes = []
               }
               if (toParams.package) {
                  if (!options.codes.includes(toParams.package)) {
                     options.codes.push(toParams.package)
                  }
               }
               return viewService.getProductFullView(productId, options).then(function (product) {
                  // If the `package` param is passed, use it
                  if (toParams.package) {
                     product.selectPackaging(toParams.package)
                     if (!product.selectedPackaging) {
                        return $q.reject({
                           status: 404,
                        })
                     }
                  }
                  //
                  // Else, if there's a user, enforce a two-tiered criteria for selecting a package:
                  //
                  // 1. If the user has ordered the product, select the most recently ordered package.
                  // 2. If the user has not ordered the product, select the default package.
                  //
                  else if (appState.userState.id) {
                     var packageToSelect
                     if (options.codes.length && options.codes[0]) {
                        packageToSelect =
                           appState.userState.orderedSummaryByPackaging(product.packaging).code || options.codes[0]
                     } else if (product.defaultPackaging) {
                        packageToSelect = product.defaultPackaging.code
                     }

                     if (packageToSelect) {
                        // Redirect to the product with the selected package in the URL
                        //
                        // Note that `$state.go` here doesn't work because we're in the context of a resolve function
                        // (so we have to use `$state.transitionTo`, of which `$state.go` is a wrapper).
                        return $state.transitionTo(
                           'shop.product',
                           angular.extend({}, toParams, {
                              package: packageToSelect,
                           })
                        )
                     }
                  }
                  return product
               })
            },
         },
         /* @ngInject */
         onEnter: function ($transition$, $state, product) {
            var toState = $transition$.to()
            var toParams = $transition$.params()

            var path = product.path
            if (path !== toParams.path) {
               return $state.target(
                  toState,
                  angular.extend({}, toParams, {
                     path: path,
                  })
               )
            }

            // Dynamic head variables
            var head = shopProduct.data.head
            head.title = product.name
            if (product.brand) {
               head.title = product.brand.name + ' ' + head.title
            }
            head.meta.keywords = product.keywords
            if (product.shortDescription) {
               head.meta.description = product.shortDescription
            }
            if (product.selectedPackagingOrDefault && product.selectedPackagingOrDefault.images.length) {
               head.primaryImageSrc =
                  'https://img.azurestandard.com/unsafe/fit-in/1200x630/filters:fill(white)/' +
                  product.selectedPackagingOrDefault.images[0]
               head.primaryImageType = 'image/jpeg'
               head.primaryImageType = 'image/jpeg'
            } else {
               delete head.primaryImageSrc
            }

            var originalTransition = $transition$.originalTransition()
            var originalParams = originalTransition.params()

            // Do a 301 redirect through Prerender if this is the initial app load and the URL is:
            // 1. an old product URL
            // 2. a short product URL
            // 3. the standard product route but the path is not the canonical path
            // This ensures only the canonical product URL is stored in Prerender's cache
            toState.data.prerenderRedirectToCanonical =
               originalTransition.from().abstract &&
               (originalTransition.to().name === 'shop.productOld' ||
                  originalTransition.to().name === 'productShort' ||
                  (originalTransition.to().name === 'shop.product' && originalParams.path !== path))
         },
         controller: 'ProductCtrl',
      }

      /* Shop Listing Pages */
      var shopListing = {
         params: {
            subcategories: {
               dynamic: true,
            },
            clearance: {
               value: false,
               type: 'bool',
               squash: true,
               dynamic: true,
            },
            tag: {
               value: [],
               array: true,
               dynamic: true,
            },
            brand: {
               value: [],
               array: true,
               dynamic: true,
            },
            category: {
               value: null,
               type: 'int',
               dynamic: true,
            },
            sort: {
               value: 'relevance',
               squash: true,
               dynamic: true,
            },
         },
         templateUrl: 'app.shop/shop_listing.index.htm',
         lazyLoad: loadOnTransition('app.shop/shop_listing.index.controller.js', [
            'app.shop/shop_listing.index.htm',
            'partials/product_list_item.htm',
            'partials/responsive_background_image_style_attr_val.htm',
         ]),
         data: {
            shopListing: true,
            head: {
               og: {
                  type: 'product.group',
               },
            },
         },
         resolve: {
            tags: function () {
               // TODO: consider refactoring this for use everywhere, such as in the main shop menu
               return [
                  {
                     slug: 'on-sale',
                     name: 'On Sale',
                     pageTitle: 'Sales',
                  },
                  {
                     slug: 'new',
                     name: 'New',
                     pageTitle: 'New Products',
                  },
               ]
            },
            /* @ngInject */
            activeOrderState: function (appState) {
               return appState.activeOrderStatePromise
            },
            /* @ngInject */
            preferences: function ($q, appState, personData) {
               // user preferences that apply to all shop pages
               return $q
                  .all([
                     personData.getPreferenceWithDefault(
                        appState.userState.id,
                        personData.preferences.preferListView,
                        false
                     ),
                     personData.getPreferenceWithDefault(
                        appState.userState.id,
                        personData.preferences.organicOnly,
                        false
                     ),
                  ])
                  .then(function (results) {
                     return {
                        preferListView: results[0],
                        organicOnly: results[1],
                     }
                  })
            },
            /* @ngInject */
            pagePreferences: function ($q, appState, personData, preferences, page) {
               // user preferences that apply to the specific shop page

               // preferListView is now a page-specific preference
               // The default is the user's existing preferListView preference,
               // unless overridden by the page
               var preferListViewDefault = preferences.preferListView
               if (page.preferListViewDefault !== undefined) {
                  preferListViewDefault = page.preferListViewDefault
               }

               return $q
                  .all([
                     personData.getPreferenceWithDefault(
                        appState.userState.id,
                        personData.preferences.preferListView + '-' + page.slug,
                        preferListViewDefault
                     ),
                     personData.getPreferenceWithDefault(
                        appState.userState.id,
                        personData.preferences.showAllSizes + '-' + page.slug,
                        false
                     ),
                  ])
                  .then(function (results) {
                     return {
                        preferListView: results[0],
                        showAllSizes: results[1],
                     }
                  })
            },
            /* @ngInject */
            favorites: function (appState, favoriteData, page) {
               if (appState.userState.isUser) {
                  var favoritesPromise = favoriteData.getFavorites(appState.userState.id)
                  if (page.slug === 'favorites') {
                     return favoritesPromise
                  }
               }
            },
            /* @ngInject */
            orderedPackagedProducts: function ($transition$, productData, appState, page) {
               var sort = $transition$.params().sort
               if (
                  page.slug === 'ordered' ||
                  sort === productData.sortBy.orderFrequency ||
                  sort === productData.sortBy.orderRecency
               ) {
                  return appState.userState.orderedPackagedProductsPromise
               }
            },
         },
         /* @ngInject */
         onExit: function ($rootScope) {
            $rootScope.mobileFilterMenuOpen = false
         },
         controller: 'ShopListingCtrl',
      }

      var shopFavorites = angular.merge({}, shopListing, {
         url: '/favorites?query?q?tag?clearance?brand?category?sort',
         params: {
            sort: {
               value: 'brand',
            },
         },
         data: {
            head: {
               title: 'Favorites',
            },
         },
         resolve: {
            page: function () {
               return {
                  slug: 'favorites',
                  preferListViewDefault: true,
               }
            },
         },
         authenticate: true,
      })

      var shopOrdered = angular.merge({}, shopListing, {
         url: '/ordered?query?q?tag?clearance?brand?category?sort',
         params: {
            sort: {
               value: 'orderFrequency',
            },
         },
         data: {
            head: {
               title: "Products I've Ordered",
            },
         },
         resolve: {
            page: function () {
               return {
                  slug: 'ordered',
               }
            },
         },
         authenticate: true,
      })

      var shopSearch = angular.merge({}, shopListing, {
         url: '/search/:query?tag?clearance?brand?category?sort',
         resolve: {
            page: function () {
               return {
                  slug: 'search',
               }
            },
         },
         /* @ngInject */
         onEnter: function ($transition$) {
            var toParams = $transition$.params()

            // Dynamic head variables
            var head = shopSearch.data.head
            head.title = 'Search results for: “' + toParams.query + '”'
         },
      })

      var shopBrand = angular.merge({}, shopListing, {
         url: '/brand/{path}/{id:int}?query?q?tag?clearance?category?sort',
         params: {
            path: {
               raw: true,
               type: 'string',
            },
         },
         resolve: {
            /* @ngInject */
            page: function ($transition$, brandData) {
               return brandData.getBrand($transition$.params().id).then(function (brand) {
                  return {
                     brand: brand,
                     slug: 'brand',
                  }
               })
            },
         },
         /* @ngInject */
         onEnter: function ($transition$, $state, page) {
            var toState = $transition$.to()
            var toParams = $transition$.params()

            var path = page.brand.slug
            if (path && path !== toParams.path) {
               toState.data.prerenderRedirectToCanonical = true
               return $state.target(
                  toState,
                  angular.extend({}, toParams, {
                     path: path,
                  })
               )
            }

            // Dynamic head variables
            var head = shopBrand.data.head
            head.title = page.brand.name
         },
      })

      var shopCategory = angular.merge({}, shopListing, {
         url: '/category/{path}/{id:int}?query?q?tag?clearance?subcategories?brand?category?sort',
         params: {
            path: {
               raw: true,
               type: 'string',
            },
         },
         resolve: {
            /* @ngInject */
            page: function ($transition$, viewService) {
               return viewService
                  .getCategoryView($transition$.params().id, {
                     ancestorViews: true,
                  })
                  .then(function (category) {
                     return {
                        category: category,
                        slug: 'category',
                     }
                  })
            },
         },
         /* @ngInject */
         onEnter: function ($transition$, $state, page) {
            var toState = $transition$.to()
            var toParams = $transition$.params()
            var category = page.category
            var path = category.path

            if (path !== toParams.path) {
               return $state.target(
                  toState,
                  angular.extend({}, toParams, {
                     id: category.id,
                     path: path,
                     category: '',
                  })
               )
            }

            // Dynamic head variables
            var head = shopCategory.data.head

            head.title = category.name
            if (category.keywords) {
               head.meta.keywords = category.keywords.join(', ')
            } else {
               delete head.meta.keywords
            }

            if (category.image) {
               head.primaryImageSrc =
                  'https://img.azurestandard.com/unsafe/fit-in/1200x630/filters:fill(white)/' + category.image
               head.primaryImageType = 'image/jpeg'
            } else {
               delete head.primaryImageSrc
            }

            var categoryRootPath = toParams.path.split('/')[0]
            switch (categoryRootPath) {
               case 'food':
                  head.meta.description =
                     'The widest selection of non-GMO & organic groceries, produce, bulk grains & flours, canned goods, grass-fed meats & dairy, animal feed & organic gardening.'
                  break
               case 'health-beauty':
                  head.meta.description =
                     'Natural beauty products, clean and organic ingredients, healing & first aid, bath & shower, organic skin care & baby products, essential oils & cosmetics.'
                  break
               case 'household-family':
                  head.meta.description =
                     'Earth friendly household cleaners, canning supplies, organic baby items, food storage, eco paper products, kitchen bags.'
                  break
               case 'nutritional-supplements':
                  head.meta.description =
                     'Azure Standard has natural and organic nutritional supplements, homeopathic and herbal remedies, organic protein powders, organic teas.'
                  break
               case 'outdoor-garden':
                  head.meta.description =
                     'The best resource for buying organic chicken and animal feed in bulk, plus hundreds of organic gardening supplies and information on organic farming.'
                  break
            }

            var originalTransition = $transition$.originalTransition()
            var originalParams = originalTransition.params()

            // Do a 301 redirect through Prerender if this is the initial app load and the URL is
            // the standard category route but the path is not the canonical path
            // This ensures only the canonical category URL is stored in Prerender's cache
            toState.data.prerenderRedirectToCanonical =
               originalTransition.from().abstract &&
               originalTransition.to().name === 'shop.category' &&
               originalParams.path !== path
         },
      })

      var shopCategoryRoot = angular.merge({}, shopListing, {
         url: '/category?query?q?tag?clearance?subcategories?brand?sort',
         data: {
            head: {
               title: 'Shop All Products',
            },
         },
         resolve: {
            page: function () {
               return {
                  slug: 'category',
               }
            },
         },
      })

      var shopTag = angular.merge({}, shopListing, {
         url: '/tag/:slug?query?q?tag?clearance?brand?category?sort',
         resolve: {
            /* @ngInject */
            page: function ($transition$, $q, tags, util) {
               // The shop by tag route currently only works for the tags defined in the resolve above.
               // This could be extended in future to support other tags
               var tagSlug = $transition$.params().slug
               var tag = util.findByPropertyValue(tags, 'slug', tagSlug)
               if (tag) {
                  return {
                     tag: tag,
                     slug: tagSlug,
                  }
               }
               return $q.reject({
                  status: 404,
               })
            },
         },
         /* @ngInject */
         onEnter: function (page) {
            // Dynamic head variables
            shopTag.data.head.title = page.tag.pageTitle
         },
      })

      var shopClearance = angular.merge({}, shopListing, {
         url: '/clearance?query?q?tag?brand?category?sort',
         data: {
            head: {
               title: 'Clearance',
            },
         },
         resolve: {
            page: function () {
               return {
                  slug: 'clearance',
               }
            },
         },
      })

      var shopBonusAzureCash = angular.merge({}, shopListing, {
         url: '/bonus-azure-cash?query?q?tag?clearance?brand?category?sort',
         data: {
            head: {
               title: 'Bonus Azure Cash',
            },
         },
         resolve: {
            /* @ngInject */
            page: function ($q, priceSettings) {
               if (priceSettings.rewardsRate) {
                  return {
                     slug: 'bonus-azure-cash',
                  }
               }
               return $q.reject({
                  status: 404,
               })
            },
         },
      })

      var checkout = {
         url: '/checkout',
         lazyLoad: loadOnTransition('app.checkout/checkout.module.js'),
      }

      var signUp = {
         parent: 'root',
         url: '/sign-up',
         templateUrl: 'app.sign_up/sign_up.htm',
         data: {
            head: {
               title: 'Sign Up',
            },
         },
         redirectTo: function (transition) {
            var appState = transition.injector().get('appState')
            if (appState.userState.id && !appState.userState.newUser) {
               return 'shop'
            }
         },
      }

      var signIn = {
         parent: 'root',
         url: '/sign-in?next?key',
         templateUrl: 'app.sign_in/sign_in.htm',
         lazyLoad: loadOnTransition('app.sign_in/sign_in.controller.js', 'app.sign_in/sign_in.htm'),
         data: {
            head: {
               title: 'Sign In',
            },
         },
         controller: 'SignInCtrl',
      }

      var signOut = {
         parent: 'root',
         url: '/sign-out',
         redirectTo: function (transition) {
            return transition.router.stateService.target('home', null, {
               reload: true,
            })
         },
      }

      var resetPasswordConfirm = {
         parent: 'root',
         url: '/reset-password/:key',
         params: {
            key: null,
         },
         templateUrl: 'app.reset_password_confirm/reset_password_confirm.htm',
         lazyLoad: loadOnTransition(
            'app.reset_password_confirm/reset_password_confirm.controller.js',
            'app.reset_password_confirm/reset_password_confirm.htm'
         ),
         data: {
            head: {
               title: 'Reset Password',
            },
         },
         controller: 'ResetPasswordConfirmCtrl',
      }

      var referralStart = {
         parent: 'root',
         url: '/start',
         templateUrl: 'app.referral_start/referral_start.htm',
         lazyLoad: loadOnTransition(
            'app.referral_start/referral_start.controller.js',
            'app.referral_start/referral_start.htm'
         ),
         controller: 'ReferralStartController',
      }

      var findDrop = {
         parent: 'root',
         url: '/drop-point-locator?q',
         params: {
            q: {
               type: 'string',
               value: null,
               dynamic: true,
            },
         },
         templateUrl: 'app.find_drop/find_drop.htm',
         lazyLoad: loadOnTransition([], ['app.find_drop/find_drop.htm', 'partials/drop_finder.htm']),
         /* @ngInject */
         controller: function ($scope) {
            this.uiOnParamsChanged = function (params) {
               // Note: This scope variable is watched inside the child
               // drop finder directive which executes the search.
               $scope.mapQuery = params.q
            }
         },
         data: {
            head: {
               title: 'Find a Drop',
            },
            shareable: true,
         },
      }

      var checkoutBegin = {
         parent: 'root',
         url: '/checkout',
         templateUrl: 'app.checkout_begin/checkout_begin.htm',
         data: {
            checkoutStep: 1,
            head: {
               title: 'Checkout - Step 1 - My Details',
            },
         },
         redirectTo: function (transition) {
            var appState = transition.injector().get('appState')
            if (appState.userState.id) {
               return transition.router.stateService.target('checkout', transition.params())
            }
         },
         /* @ngInject */
         controller: function ($scope, cookieService, appState) {
            $scope.orderState = appState.activeOrderState
            if (Azure.canReadCookies) {
               var guestCookie = cookieService.get('g')
               $scope.signIn = Azure.hasIdCookie() && guestCookie !== 'True'
            }
         },
      }

      var checkoutConfirmation = {
         parent: 'root',
         url: '/checkout/confirmation/{id:int}',
         templateUrl: 'app.checkout_confirmation/checkout_confirmation.htm',
         lazyLoad: loadOnTransition([], 'app.checkout_confirmation/checkout_confirmation.htm'),
         authenticate: true,
         data: {
            checkoutStep: 5,
            head: {
               title: 'Checkout - Step 5 - Order Confirmation',
            },
         },
         resolve: {
            /* @ngInject */
            orderState: function ($q, $transition$, appState) {
               var orderId = $transition$.params().id
               var orderState = appState.createOrderState({
                  id: orderId,
               })

               return orderState
                  .refresh({
                     dropView: {},
                     tripView: {},
                     stopView: {},
                     paymentMethodView: {},
                     lineViews: {},
                  })
                  .then(function (orderState) {
                     if (
                        !(orderState.order.isStatusPlaced || orderState.order.isStatusConfirmed) ||
                        orderState.order.customer !== appState.userState.id
                     ) {
                        return $q.reject({
                           status: 404,
                        })
                     }
                     return orderState
                  })
            },
         },
         /* @ngInject */
         controller: function ($scope, orderState) {
            $scope.orderState = orderState
            $scope.featurePath = 'checkoutConfirmation'
         },
      }

      var data = {
         parent: 'root',
         url: '/data',
         templateUrl: 'app.data/data.htm',
      }

      // Generic error page (if error code is not 404)
      var error = {
         parent: 'root',
         templateUrl: 'app.error/error.htm',
         params: {
            status: null,
         },
         data: {
            head: {
               title: 'An Error Occurred',
            },
         },
         /* @ngInject */
         onEnter: function ($transition$) {
            error.data.head.meta['prerender-status-code'] = $transition$.params().status
         },
      }

      var notFound = {
         parent: 'root',
         url: '{path:slashesAllowed}',
         templateUrl: 'app.404/404.htm',
         data: {
            head: {
               title: 'Page Not Found',
               meta: {
                  'prerender-status-code': '404',
               },
            },
         },
      }

      var cmsHomeVariations = {
         parent: 'root',
         url: '/?userType',
         params: {
            userType: null,
         },
         lazyLoad: loadOnTransition([], ['partials/block_image.htm', 'partials/block_image_slider.htm']),
         /* @ngInject */
         templateProvider: function ($q, $stateParams, $templateCache, $templateRequest, util, appState) {
            if (Azure.homePageRequest) {
               var homePageRequest = Azure.homePageRequest
               Azure.homePageRequest = undefined
               return util.resolveXhr(homePageRequest).then(function (responseText) {
                  $templateCache.put(Azure.homePageTemplatePath, responseText)
                  return responseText
               })
            }

            // Allow overridding the default home page via the query param `userType`
            // Note: The value must match the expected value that would be set on `appState.userState.homePageVersion`.
            var homePageVersion = $stateParams.userType || appState.userState.homePageVersion
            var templatePath

            switch (homePageVersion) {
               case 'visitor':
                  templatePath = 'app.from_cms/home/home-visitor.htm'
                  break
               case 'retail':
                  templatePath = 'app.from_cms/home/home-retail.htm'
                  break
               case 'wholesale':
                  templatePath = 'app.from_cms/home/home-wholesale.htm'
                  break
               default:
                  return $q.reject({
                     status: 404,
                  })
            }

            return $templateRequest(templatePath, true)
         },
         resolve: {
            /* @ngInject */
            pageData: function ($transition$, $http, $q, appState, util) {
               var homePageVersion = $transition$.params().userType || appState.userState.homePageVersion
               return fetchPageData(
                  'app.from_cms/home/_data-home' + util.capitalize(homePageVersion) + '.json',
                  'home'
               )($transition$, $http, $q).then(function (data) {
                  return Azure.isCmsPreview
                     ? Object.assign({}, data[homePageVersion], {
                          _isInternalPreviewDraftData: data._isInternalPreviewDraftData,
                       })
                     : data
               })
            },
         },
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData

            $scope.setProductListLiStyle = function (content, item) {
               /* jshint -W106 */
               var isLiBoxed = content.list_style === 'box'

               if (isLiBoxed && item.override_default_box_style) {
                  return {
                     'background-color': item.color_button,
                     color: item.color_text,
                  }
               }

               if (isLiBoxed) {
                  return {
                     'background-color': content.item_default_color_button,
                     color: content.item_default_color_text,
                  }
               }
            }

            $scope.loadTrustpilotWidget = function (domLookup) {
               window.setTimeout(function () {
                  var domElement = document.querySelector(domLookup)
                  window.Trustpilot.loadFromElement(domElement)
               })
            }
         },
      }

      var cmsAzureLifeIndex = {
         parent: 'root',
         url: '/azure-life',
         templateUrl: 'app.from_cms/azure-life/azure_life_page.htm',
         lazyLoad: loadOnTransition([], 'app.from_cms/azure-life/azure_life_page.htm'),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/azure-life/_data/_azure-life-index.json', 'azureLifeIndex'),
         },
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData
         },
      }

      var cmsAzureLifeEntry = {
         parent: 'root',
         url: '/azure-life/{slug}',
         params: {
            slug: null,
         },
         templateUrl: 'app.from_cms/azure-life/azure_life_page.htm',
         lazyLoad: loadOnTransition([], 'app.from_cms/azure-life/azure_life_page.htm'),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/azure-life/_data/{slug}.json', 'azureLifeEntry'),
         },
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData
         },
      }

      var cmsAzureLifeSearch = {
         parent: 'root',
         url: '/azure-life/search/:query?scope?',
         templateUrl: 'app.from_cms/azure-life/azure_life_search.htm',
         lazyLoad: loadOnTransition(
            'app.from_cms/azure-life/azure_life_search.controller.js',
            'app.from_cms/azure-life/azure_life_search.htm'
         ),
         params: {
            query: {
               value: '',
               squash: true,
            },
            scope: {
               dynamic: true,
            },
         },
         data: {
            head: {
               meta: {
                  description: 'Find recipes, blog posts, and other Azure Life content.',
               },
            },
            shareable: true,
         },
         controller: 'AzureLifeSearchController',
         /* @ngInject */
         onEnter: function ($transition$) {
            var toParams = $transition$.params()

            if (toParams.query) {
               cmsAzureLifeSearch.data.head.title = 'Azure Life search for “' + toParams.query + '”'
            } else {
               cmsAzureLifeSearch.data.head.title = 'Azure Life - Search'
            }
         },
      }

      var cmsAuthorsIndex = {
         parent: 'root',
         url: '/azure-life/authors',
         templateUrl: 'app.from_cms/authors/authors_index.htm',
         lazyLoad: loadOnTransition([], 'app.from_cms/authors/authors_index.htm'),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/authors/_data/_authors-index.json', 'authorsIndexAndEntries'),
         },
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData
         },
      }

      var cmsAuthorsEntry = {
         parent: 'root',
         url: '/azure-life/authors/{slug}/{uuid}',
         params: {
            slug: null,
            uuid: null,
         },
         templateUrl: 'app.from_cms/authors/authors_entry.htm',
         lazyLoad: loadOnTransition([], 'app.from_cms/authors/authors_entry.htm'),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/authors/_data/{uuid}.json', 'authorsEntry'),
            /* @ngInject */
            algoliaDataBlog: function ($transition$, cmsData) {
               return cmsData.searchBlog({
                  filters: 'author.slug:' + $transition$.params().slug,
               })
            },
            /* @ngInject */
            algoliaDataRecipes: function ($transition$, cmsData) {
               return cmsData.searchRecipes({
                  filters: 'author.slug:' + $transition$.params().slug,
               })
            },
         },
         /* @ngInject */
         onEnter: maybeRedirectCmsUuidBasedRoute(),
         /* @ngInject */
         controller: function (pageData, algoliaDataBlog, algoliaDataRecipes, $rootScope, $scope, util) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData
            $scope.formatTotalMinutes = util.formatTotalMinutes

            $scope.recentPosts = algoliaDataBlog.hits && algoliaDataBlog.hits.slice(0, 3)
            $scope.totalPostCount = algoliaDataBlog.nbHits

            $scope.recentRecipes = algoliaDataRecipes.hits && algoliaDataRecipes.hits.slice(0, 3)
            $scope.totalRecipeCount = algoliaDataRecipes.nbHits
         },
      }

      var cmsCategoriesIndex = {
         parent: 'root',
         url: '/azure-life/categories',
         templateUrl: 'app.from_cms/categories/categories_index.htm',
         lazyLoad: loadOnTransition([], 'app.from_cms/categories/categories_index.htm'),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/categories/_data/_categories-index.json', 'categoryIndexAndEntries'),
         },
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData
         },
      }

      var cmsCategoriesEntry = {
         parent: 'root',
         url: '/azure-life/categories/{slug}/{uuid}',
         templateUrl: 'app.from_cms/categories/categories_entry.htm',
         lazyLoad: loadOnTransition(
            'app.from_cms/categories/categories_entry_page.controller.js',
            'app.from_cms/categories/categories_entry.htm'
         ),
         params: {
            slug: null,
            uuid: null,
         },
         data: {
            shareable: true,
         },
         controller: 'CategoriesSearchPageController',
         resolve: {
            pageData: fetchPageData('/app.from_cms/categories/_data/{uuid}.json', 'category'),
            /* @ngInject */
            algoliaData: function ($transition$, cmsData) {
               return cmsData.search({filters: 'categories.parents:' + $transition$.params().uuid})
            },
         },
         /* @ngInject */
         onEnter: maybeRedirectCmsUuidBasedRoute(),
      }

      var cmsSubcategoriesEntry = {
         parent: 'root',
         url: '/azure-life/categories/{parentSlug}/{slug}/{uuid}',
         templateUrl: 'app.from_cms/categories/categories_entry.htm',
         lazyLoad: loadOnTransition(
            'app.from_cms/categories/categories_entry_page.controller.js',
            'app.from_cms/categories/categories_entry.htm'
         ),
         params: {
            parentSlug: null,
            slug: null,
            uuid: null,
         },
         data: {
            shareable: true,
         },
         controller: 'CategoriesSearchPageController',
         resolve: {
            pageData: fetchPageData('/app.from_cms/categories/_data/{uuid}.json', 'subCategory'),
            /* @ngInject */
            algoliaData: function ($transition$, cmsData) {
               return cmsData.search({filters: 'categories.children:' + $transition$.params().uuid})
            },
         },
         /* @ngInject */
         onEnter: maybeRedirectCmsUuidBasedRoute(),
      }

      var cmsBlogEntry = {
         parent: 'root',
         url: '/azure-life/blog/{slug}/{uuid}',
         params: {
            slug: null,
            uuid: null,
         },
         templateUrl: 'app.from_cms/blog/blog_entry.htm',
         lazyLoad: loadOnTransition([], 'app.from_cms/blog/blog_entry.htm'),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/blog/_data/{uuid}.json', 'blogEntry'),
         },
         /* @ngInject */
         onEnter: maybeRedirectCmsUuidBasedRoute(),
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope, yamzService) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData

            if (!$rootScope.viewportXs) {
               yamzService.init()
            }

            if (pageData.relatedProductIds && pageData.relatedProductIds.length) {
               $scope.relatedProductIds = pageData.relatedProductIds
            } else if (pageData.hlData && pageData.hlData.related_products) {
               $scope.relatedProductIds = pageData.hlData.related_products.split(',')
            }
         },
      }

      var cmsRecipesEntry = {
         parent: 'root',
         url: '/azure-life/recipes/{slug}/{uuid}',
         params: {
            slug: null,
            uuid: null,
         },
         templateUrl: 'app.from_cms/recipes/recipes_entry.htm',
         lazyLoad: loadOnTransition(
            'app.from_cms/recipes/recipes_entry_controller.js',
            'app.from_cms/recipes/recipes_entry.htm'
         ),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/recipes/_data/{uuid}.json', 'recipesEntry'),
            /* @ngInject */
            commentAndRatingStats: function ($transition$, AzureAlgolia, config) {
               var recipesAlgoliaIndex = AzureAlgolia.createIndexProxy(config.algoliaIndexNames.cms, {
                  // TODO: Not sure if this should be `true`... keeping if `false` for now just to be safe.
                  cacheObjects: false,
               })

               return recipesAlgoliaIndex.getObject($transition$.params().uuid, [
                  'commentCount',
                  'ratingAverage',
                  'ratingCount',
               ])
            },
         },
         /* @ngInject */
         onEnter: maybeRedirectCmsUuidBasedRoute(),
         controller: 'recipesEntryController',
      }

      var cmsVideosEntry = {
         parent: 'root',
         url: '/azure-life/videos/{slug}/{uuid}',
         params: {
            slug: null,
            uuid: null,
         },
         templateUrl: 'app.from_cms/videos/videos_entry.htm',
         lazyLoad: loadOnTransition([], 'app.from_cms/videos/videos_entry.htm'),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/videos/_data/{uuid}.json', 'videosEntry'),
         },
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope, yamzService) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData

            if (!$rootScope.viewportXs) {
               yamzService.init()
            }

            if (pageData.relatedProductIds && pageData.relatedProductIds.length) {
               $scope.relatedProductIds = pageData.relatedProductIds
            }
         },
         /* @ngInject */
         onEnter: maybeRedirectCmsUuidBasedRoute(),
      }

      var cmsCoreValuesIndex = {
         parent: 'root',
         url: '/core-values',
         templateUrl: 'app.from_cms/core-values/core_values_index.htm',
         lazyLoad: loadOnTransition([], 'app.from_cms/core-values/core_values_index.htm'),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/core-values/_data/_data-coreValues.json', 'coreValues'),
         },
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData
         },
      }

      var cmsCoreValuesEntry = {
         parent: 'root',
         url: '/core-values/{slug}/{uuid}',
         params: {
            slug: null,
            uuid: null,
         },
         templateUrl: 'app.from_cms/core-values/core_values_entry.htm',
         lazyLoad: loadOnTransition([], 'app.from_cms/core-values/core_values_entry.htm'),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/core-values/_data/{uuid}.json', 'coreValuesEntry'),
         },
         /* @ngInject */
         onEnter: maybeRedirectCmsUuidBasedRoute(),
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData

            function createPageParams(dataObj) {
               return '{slug: "' + dataObj.slug + '", uuid: "' + dataObj.uuid + '"}'
            }

            $scope.previousUiSref = pageData.previous
               ? 'cmsCoreValuesEntry(' + createPageParams(pageData.previous) + ')'
               : 'cmsCoreValuesIndex'

            $scope.nextUiSref = pageData.next
               ? 'cmsCoreValuesEntry(' + createPageParams(pageData.next) + ')'
               : 'cmsCoreValuesIndex'
         },
      }

      var cmsTopLevelPage = {
         parent: 'root',
         url: '/{slug}',
         params: {
            slug: null,
         },
         templateUrl: 'app.from_cms/top-level-pages/top_level_pages_entry.htm',
         lazyLoad: loadOnTransition([], 'app.from_cms/top-level-pages/top_level_pages_entry.htm'),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/top-level-pages/_data/{slug}.json', 'topLevelPageBySlug'),
         },
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope, yamzService) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData
            if (!$rootScope.viewportXs) {
               yamzService.init()
            }
         },
      }

      var cmsProductInsight = {
         parent: 'root',
         url: '/product-insight/{slug}',
         params: {
            slug: null,
         },
         data: {
            shareable: true,
         },
         /* @ngInject */
         templateProvider: function ($transition$, $http, $templateRequest) {
            // The `_cmsTemplateHashesByPath.json` file is a key-value object of the site's templates that's only built
            // and included in the root of the built site if we're hashing our files.
            // Since we have no way at runtime to know whether the site's files were hashed, here we're attempting to
            // fetch the file (via `$http`) and, if it's present, we serve the hashed template (e.g. in production).
            // Otherwise, we fallback to serving the non-hashed template (e.g. in development and Netlify previews).
            var filePathWithoutHash = '/product-insight/product-insight_' + $transition$.params().slug
            if (!Azure.areAssetsHashed) {
               return $templateRequest('app.from_cms' + filePathWithoutHash + '.htm')
            } else {
               return $http.get('_cmsTemplateHashesByPath.json', {cache: true}).then(function (response) {
                  return $templateRequest(
                     'app.from_cms' + filePathWithoutHash + '.' + response.data[filePathWithoutHash] + '.htm'
                  )
               })
            }
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/product-insight/_data/{slug}.json', 'productInsightPageBySlug'),
         },
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData
         },
      }

      var cmsCareersIndex = {
         parent: 'root',
         url: '/careers',
         templateUrl: 'app.from_cms/careers/careers_index.htm',
         lazyLoad: loadOnTransition([], 'app.from_cms/careers/careers_index.htm'),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/careers/_data/_careers-index.json', 'careersIndexAndEntries'),
         },
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData
         },
      }

      var cmsCareersEntry = {
         parent: 'root',
         url: '/careers/{slug}/{uuid}',
         params: {
            slug: null,
            uuid: null,
         },
         templateUrl: 'app.from_cms/careers/careers_entry.htm',
         lazyLoad: loadOnTransition([], 'app.from_cms/careers/careers_entry.htm'),
         data: {
            shareable: true,
         },
         resolve: {
            pageData: fetchPageData('/app.from_cms/careers/_data/{uuid}.json', 'careersEntry'),
         },
         /* @ngInject */
         onEnter: maybeRedirectCmsUuidBasedRoute(),
         /* @ngInject */
         controller: function (pageData, $rootScope, $scope) {
            $rootScope.onCmsPageInit(pageData)
            $scope.pageData = pageData
         },
      }

      // ===============================================================================

      $stateProvider
         .state('root', root)
         .state('account.**', account)
         .state('add', add)
         .state('addResult', addResult)
         .state('productShort', productShort)
         .state('dc.**', dc)
         .state('dd.**', dd)
         .state('shop', shop)
         .state('shop.brands', shopBrands)
         .state('shop.search', shopSearch)
         .state('shop.brand', shopBrand)
         .state('shop.tag', shopTag)
         .state('shop.favorites', shopFavorites)
         .state('shop.ordered', shopOrdered)
         .state('shop.clearance', shopClearance)
         .state('shop.bonusAzureCash', shopBonusAzureCash)
         .state('shop.category', shopCategory)
         .state('shop.categoryRoot', shopCategoryRoot)
         .state('shop.productOld', shopProductOld)
         .state('shop.product', shopProduct)
         .state('checkout.**', checkout)
         .state('signUp', signUp)
         .state('signIn', signIn)
         .state('signOut', signOut)
         .state('resetPasswordConfirm', resetPasswordConfirm)
         .state('referralStart', referralStart)
         .state('findDrop', findDrop)
         .state('checkoutBegin', checkoutBegin)
         .state('checkoutConfirmation', checkoutConfirmation)
         .state('data', data)
         // From CMS
         .state('home', cmsHomeVariations)
         .state('cmsAzureLifeIndex', cmsAzureLifeIndex)
         .state('cmsAzureLifeEntry', cmsAzureLifeEntry)
         .state('cmsAzureLifeSearch', cmsAzureLifeSearch)
         .state('cmsAuthorsIndex', cmsAuthorsIndex)
         .state('cmsAuthorsEntry', cmsAuthorsEntry)
         .state('cmsCategoriesIndex', cmsCategoriesIndex)
         .state('cmsCategoriesEntry', cmsCategoriesEntry)
         .state('cmsSubcategoriesEntry', cmsSubcategoriesEntry)
         .state('cmsBlogEntry', cmsBlogEntry)
         .state('cmsRecipesEntry', cmsRecipesEntry)
         .state('cmsProductInsight', cmsProductInsight)
         .state('cmsCoreValuesIndex', cmsCoreValuesIndex)
         .state('cmsCoreValuesEntry', cmsCoreValuesEntry)
         .state('cmsTopLevelPage', cmsTopLevelPage)
         .state('cmsCareersIndex', cmsCareersIndex)
         .state('cmsCareersEntry', cmsCareersEntry)
         .state('cmsVideosEntry', cmsVideosEntry)
         // These (error and 404 states) should always be last as "catch alls"
         .state('error', error)
         .state('404', notFound)
   }
})(angular, window.Azure)
